import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import {Link} from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Topnav from '../components/Topnav'
import Scroll from './Scroll'


const useStyles = makeStyles(theme => ({
  root: {    
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },    
  },
  tree:{
    padding: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    },
  },
  list : {
    width : 200,
  },  
  sideBarIcon : {
    color : "white",
    cursor : "pointer",
  },
  link:
    { 
      fontSize: '20px',
      textDecoration: 'none',
      color: 'white',
      display:'flex',
      fontFamily: "Poppins, sans-serif",
      marginBottom:'10px', 
      justifyContent: 'space-evenly',
      '&:hover': {
        transform:'scale(1.1)',
        transition: 'transform .7s ease-in-out',
        color:'#e4e4e4',
     },   
    },
    reslink:
    {
        textDecoration: 'none',
        color: '#213C54',
    },
     reslink2:
    {
        textDecoration: 'none',
        color: '#213C54',
       fontSize:'15px'
    },
    
}));

function ElevationScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}


ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,  
  window: PropTypes.func,
};

function ElevateAppBar(props) {
  const classes = useStyles();
 

  return (    
    <React.Fragment>
      <Topnav/>
      <ElevationScroll {...props}> 
      <AppBar className={classes.root} position="sticky" style={{ backgroundColor: '#091a2d'  ,
           color: 'white', height:'55px'}}>        
        <Toolbar >
        <Typography component={'span'}  align="center"> 
        <Scroll/>       
          </Typography>
          <Typography component={'span'} color="inherit" style={{flexGrow:1}}> 
          <Link to="/design&develop" className={classes.link}>DESIGN & DEVELOP
          </Link></Typography>
          <Typography component={'span'}  color="inherit" style={{flexGrow:1}}> 
          <Link to="/manufacture" className={classes.link}>MANUFACTURE</Link></Typography>
          <Typography component={'span'}  color="inherit" style={{flexGrow:1}}> 
          <Link to="/distribute" className={classes.link}>DISTRIBUTE</Link></Typography>
          <Typography component={'span'}   color="inherit"style={{flexGrow:1}} >
            <Link to="/ourbrands" className={classes.link}>OUR BRANDS
            </Link></Typography>    
      </Toolbar>
      </AppBar>
      </ElevationScroll>    
      
    </React.Fragment>
  );
};


export default ElevateAppBar;