import Container from "@material-ui/core/Container";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import ReactWOW from "react-wow";
import design from './../assets/designing-equipment.jpg'
import designb from './../assets/webp/designing-equipment.webp'
import develop from './../assets/secondary-equipment.jpg'
import developb from './../assets/webp/secondary-equipment.webp'
import manufacture from './../assets/webp/distribute.webp'
import manufactureb from './../assets/webp/distribute.webp'
import CardActions from '@material-ui/core/CardActions';
import './../App.css'

const tiers = [
  {
    id:1,
    src: require("../assets/designing-equipment.jpg"),
    fallbacksrc:require("../assets/webp/designing-equipment.webp"),
    backsrc: require("../assets/desigining.jpg"),
    fallbackbacksrc: require("../assets/webp/designing.webp"),
    title: "Design & Develop",
    subtitle: "Dedicated In-House Engineering Staff",
    path: "/design&develop",
  },
  {
    id:2,
    src: require("../assets/secondary-equipment.jpg"),
    fallbacksrc:require("../assets/webp/secondary-equipment.webp"),
    backsrc: require("../assets/CNC-machines.jpg"),
    fallbackbacksrc: require("../assets/webp/CNC-machines.webp"),
    title: "Manufacture",
    subtitle: "High Production CNC Machines and Secondary Equipment",
    path: "/manufacture",
  },
  {
    id:3,
    src: require("../assets/distribute.jpg"),
    fallbacksrc:require("../assets/webp/distribute.webp"),
    backsrc: require("../assets/packing-packages.jpg"),
    fallbackbacksrc: require("../assets/webp/packing-packages.webp"),
    title: "Distribute",
    subtitle: "Optimized Process for Same Day Order Processing",
    path: "/distribute",
  },
];

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  paper: {
    padding: "10px",
    textAlign: "center",
  },
  tier: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  button: {
    color: "#091a2d",
    justifyContent: "center",
    backgroundColor: "#E2ECEE",
    fontSize:'16px',
    border:'none',
    padding:'4px 6px',
    cursor:'pointer',
    fontFamily: "Poppins, sans-serif",
     '&:hover': {
    backgroundColor: "#99B9C0",
    color: "#091a2d",
}, },
  link: {
    textDecoration: "none",
  },
  cardMedia2: {
    maxHeight: "200px",
  },
});

export default function Content() {
  const classes = useStyles();

  return (
    <div>
      {/* large view */}
      {/* <Box display={{ xs: "none", sm: "block" }}> */}
        <ReactWOW animation="fadeIn">
          <Container
            maxWidth="lg"
            component="main"
            id="content"
            style={{ marginTop: "80px" }}
          >
            <Grid
              container
              spacing={2}
              alignItems="flex-end"
              style={{ marginBottom: "20px" }}
            >

              <Grid item xs={12} md={4} sm={6} className="animate">
              <Link to="/design&develop" style={{textDecoration:'none'}}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia className={classes.media}>
                    <picture>
                    <source srcSet={designb} style={{ height: "240px" }} />
                    <img src={design} style={{ height: "240px" }} />
                  </picture>
                    </CardMedia>

                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{
                          fontSize: "28px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Design & Develop
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{
                          fontSize: "17px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Dedicated Innovative In-House Engineering Staff
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>                    
                 
                    <Button className={classes.button}>
                      Read More
                    </Button>
                    
                  </CardActions>
                </Card>
                </Link>
              </Grid>

              <Grid item xs={12} md={4} sm={6} className="animate">
              <Link to="/manufacture" style={{textDecoration:'none'}}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia className={classes.media}>
                      <picture>
                        <source srcset={developb} style={{ height: "240px" }} />
                        <img src={develop} style={{ height: "240px" }} />
                      </picture>
                    </CardMedia>

                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{
                          fontSize: "28px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Manufacture
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{
                          fontSize: "17px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        High Production CNC Machines and Secondary Equipment
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                 
                    <Button className={classes.button}>
                      Read More
                    </Button>
                   
                  </CardActions>
                </Card>
                </Link>
              </Grid>
              <Grid item xs={12} md={4} sm={6} className="animate">
              <Link to="/distribute" style={{textDecoration:'none'}}>
                <Card className={classes.root}>
                  <CardActionArea>
                    <CardMedia className={classes.media}>
                    <picture>
                  <source srcset={manufactureb} style={{ height: "240px" }} />
                  <img src={manufacture} style={{ height: "240px" }} />
                </picture>
                    </CardMedia>

                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="h2"
                        style={{
                          fontSize: "28px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                        Distribute
                      </Typography>
                      <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                        style={{
                          fontSize: "17px",
                          fontFamily: "Poppins, sans-serif",
                        }}
                      >
                       Optimized Process for Same Day Order Processing
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                  <CardActions>
                  
                    <Button className={classes.button}>
                      Read More
                    </Button>
                  </CardActions>
                </Card>
                </Link>
              </Grid>
             
            </Grid>

            {/* end test */}
            {/* <Grid
            container
            spacing={3}
            alignItems="flex-end"
            style={{ marginBottom: "20px" }}
          >
            {tiers.map((tier) => (
              <Grid item key={tier.id} xs={12} md={4} sm={6}>
                <Flippy
                  flipOnHover={true} // default false
                  flipOnClick={false} // default false
                  flipDirection="horizontal"
                >
                  <FrontSide style={{ backgroundColor: "#e4e4e4" }}>
                    <CardActionArea>
                      <CardMedia
                        component="img"
                        alt="Dedicated In-House Engineering Staff"
                        height="240"
                        image={tier.src}
                      />
                      <CardContent style={{ paddingBottom: "0px" }}>
                        <Typography component="h2"
                          align="center" 
                          style={{ paddingBottom: "0px", fontSize:'28px',  fontFamily: "Poppins, sans-serif" }}
                        >
                          {tier.title}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </FrontSide>
                  <Link to={tier.path} className={classes.link}>
                    <BackSide
                      style={{ backgroundImage: `url(${tier.backsrc})` }}
                    >
                      <div style={{ marginTop: "18%" }}>
                        <CardContent>
                          <Typography component="h2"
                            align="center"
                            style={{
                              color: "#091a2d",
                              fontSize: "26px",
                              backgroundColor: "white",
                              height: "130px",
                              padding: "10px",  fontFamily: "Poppins, sans-serif"
                            }}
                          >
                            {tier.subtitle}{" "}
                          </Typography>
                        </CardContent>
                        <Typography component="h2" align="center">
                          <button className={classes.button}>
                               Read More
                          </button>
                        </Typography>
                      </div>
                    </BackSide>{" "}
                  </Link>
                </Flippy>
              </Grid>
            ))}
          </Grid> */}
          </Container>
        </ReactWOW>
      {/* </Box> */}

      {/* mobile */}
      {/* <Box display={{ xs: "block", sm: "none" }}>
        <Container className={classes.cardGrid} maxWidth="md">
          
          <Grid container spacing={2} style={{ marginTop: "20px" }}>
            {tiers.map((card) => (
              <Grid item key={card.id} xs={12}>
                <Card className={classes.card}>
                  <CardMedia
                    component="img"
                    className={classes.cardMedia2}
                    image={card.src}
                    title={card.title}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      component="h2"
                      align="center"
                      style={{ fontSize: "16px" }}
                    >
                      {card.title}
                    </Typography>
                  </CardContent>
                  <div align="center" style={{ paddingBottom: "20px" }}>
                    <Link to={card.path} className={classes.link}>
                      <Button
                        size="small"
                        variant="contained"
                        className={classes.button}
                        style={{
                          textAlign: "center",
                          fontSize: "12px",
                          padding: "5px",
                        }}
                      >
                        Read More
                      </Button>
                    </Link>
                  </div>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
                      </Box> */}
    </div>
  );
}
