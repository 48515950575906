
import React from 'react'
import invlogo from '../assets/invlogo.svg'
import { Link } from 'react-router-dom'
import { Grid } from '@material-ui/core';

  class Scroll extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        display : false
    }
    }
    componentDidMount() {
      document.addEventListener("scroll", () => {
        const img = window.scrollY < 150 ? false : true;
        this.setState({ display: img });
      });
    }
    render() {
    
      return (
        <Grid 
          style={{            
            position: "relative",           
          }}>
           {this.state.display ? <Smallimg/> : null }        
        </Grid>
      )
    }
  }
   export default Scroll;
   function Smallimg() {
    const trigger = () => {
      window.scrollTo(0, 0);
    };

    return (
        <Grid> 
          <Link to="/" onClick={trigger}>           
            <img src={invlogo} alt="" style={{height: '30px',  paddingLeft: '20px', marginBottom:'10px', display: 'block', transform:'scale(1.1)',transition: 'transform .5s ease', transitionDelay: '2s', cursor:'pointer'}} /></Link>
        </Grid>
    )
}