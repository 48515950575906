import React, { Component } from 'react';
// import the component
import Mailchimp from 'react-mailchimp-form'
 
class App extends Component {
  render() {
    return (
        <div
        style={{          
          padding: 5,
        }}
      >
        <Mailchimp
        action='https://E-Rigging.us18.list-manage.com/subscribe/post?u=79ae2d8078facdd05bd62257b&amp;id=11b453bc79' method="post"
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Email',
            type: 'email',
            required: true
          }
         
        ]}
        messages = {
          {
            sending: "Subscribing...",
            success: "Please verify your email to continue with the subscription. Do not forget to check your Spam folder.",
            error: "An unexpected internal error has occurred.",
            empty: "You must write an e-mail.",
            duplicate: "Too many subscribe attempts for this email address",
            button: "Sign up"
          }
        }
        className='form'
        />
        </div>
    );
  }
}
 
export default App;
