import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Img2b from '../assets/webp/design-and-develop.webp'
import Img1b from '../assets/webp/designing-equipment.webp'
import Rop from '../assets/logos/ropeze.png'
import Ropb from '../assets/logos/webp/ropeze.webp'
import Sea from '../assets/logos/sealand.png'
import Seab from '../assets/logos/webp/sealand.webp'
import Tri from '../assets/logos/trident.png'
import Trib from '../assets/logos/webp/trident.webp'
import Pro from '../assets/logos/prostrand.png'
import Prob from '../assets/logos/webp/prostrand.webp'
import Lyn from '../assets/logos/lynx.png'
import Lynb from '../assets/logos/webp/lynx.webp'
import Fib from '../assets/logos/fiberloy.png'
import Fibb from '../assets/logos/webp/fiberloy.webp'
import { Link } from 'react-router-dom'
import ModalImage from "react-modal-image";
import ReactWOW from 'react-wow' 
import ImgOpt from './ImgOpt'
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({
   pagetitle:{
    color: '#231f20',
    paddingTop:'30px',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: 600,
    fontSize:'55px',
    [theme.breakpoints.down("md")]: {
      fontSize: "35px", 
    },
  },
  pagesubtitle:{
    fontFamily: 'Poppins, sans-serif',
    paddingBottom: '40px', 
    fontSize:'25px',
    [theme.breakpoints.down("md")]: {
      fontSize: "23px", 
    },
  },
  pageContent: {
    padding: '30px',
    fontSize:'18px',
    fontFamily: "Poppins, sans-serif",
  },
  img: {
    maxHeight: '70px'
  },
  card: {
    maxHeight: '340px'
  },
}));


export default function Product(children) {
  const classes = useStyles();

  return (
    <div>    
      <Helmet>
        <title>
          Design & Develop
        </title>
      </Helmet>
      <ReactWOW animation='fadeIn'>
      <Container maxWidth="md" component="main" >
        <Typography component="h2"  align="center"className={classes.pagetitle} >
          DESIGN & DEVELOP
        </Typography>
        <Typography component="h2" align="center" className={classes.pagesubtitle}>
        Dedicated In-House Engineering Staff
        </Typography>
      </Container>
      <Container maxWidth="md" component="main">
      <Grid container spacing={2} justify="center" alignItems="center">                    
          <Grid item  xs={12} sm={6} md={6}>
                <ModalImage
                      small={Img1b}
                      large={Img1b} hideDownload={true} hideZoom={true}
                      className={classes.card} alt="Dedicated In-House Engineering Staff"
                      />    
              </Grid>  
              <Grid item  xs={12} sm={6} md={6}>
                <ModalImage
                      small={Img2b}
                      large={Img2b} hideDownload={true} hideZoom={true}
                      className={classes.card} alt="Dedicated In-House Engineering Staff"
                    />   
              </Grid> 
              <Typography component={'span'}  className={classes.pageContent}>
              Can we make it better or do it better? That is the question that begins each product or product line we create a path for. Late nights, early mornings, scratch pads and white boards become the medium for innovation. Achieving the most practical of designs having the right features is an addictive and rewarding challenge. 
                 </Typography> 
        </Grid>
        <Typography component="h2"  align="center"  className={classes.pagesubtitle} style={{padding:'5px'}}> Brands We've Designed and Developed
                    </Typography>
        <Grid container spacing={2} justify= 'center' alignItems="center">       
          <Grid item md={2} sm={3} xs={6} style={{textAlign:'center'}}>
            <Link to='/ourbrands'>           
            <ImgOpt src={Ropb} fallbacksrc={Rop} alt="Ropeze" className={classes.img}/></Link>
          </Grid>
         
          <Grid item md={2} sm={3} xs={6} style={{textAlign:'center'}}>
          <Link to='/ourbrands'>
          <ImgOpt src={Trib} fallbacksrc={Tri} alt="Trident" className={classes.img}/></Link>
          </Grid>
          <Grid item md={2} sm={3} xs={6} style={{textAlign:'center'}}>
          <Link to='/ourbrands'>
          <ImgOpt src={Prob} fallbacksrc={Pro} alt="ProStrand" className={classes.img}/></Link>
          </Grid>
          <Grid item md={2} sm={3} xs={6} style={{textAlign:'center'}}>
          <Link to='/ourbrands'>
          <ImgOpt src={Lynb} fallbacksrc={Lyn} alt="Lynx" className={classes.img}/></Link>
          </Grid>
          <Grid item md={2} sm={3} xs={6} style={{textAlign:'center'}}>
          <Link to='/ourbrands'>
          <ImgOpt src={Fibb} fallbacksrc={Fib} alt="Fiberloy" className={classes.img}/></Link>
          </Grid>
        </Grid>
        </Container> 
        <div style={{marginBottom: '50px'}}></div>
        </ReactWOW>
      </div>
    
  );
}