import React from 'react'

export default function ImgOpt({src, alt, fallbacksrc, mediatype='image/webp', ...delegated}) {
    return (
        <picture>
            <source srcSet={src} type={mediatype}/>
            <img src={fallbacksrc} alt={alt} {...delegated}/>
        </picture>
    )
}
