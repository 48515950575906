import "./App.css";
import React, { useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import Distribute from "./components/Distribute";
import DesignDevelop from "./components/DesignDevelop";
import Manufacture from "./components/Manufacture";
import Brands from "./components/Brand";
import Home from "./components/Home";
import Termsofuse from "./components/Termsofuse";
import Privacy from "./components/Privacy";
import Contactform from "./components/Contactform";
import Box from "@material-ui/core/Box";
import Drawer from "./components/Drawer";
import Footer from "./components/Footer";
import Navbar from "./components/Appbar";
import { Grid } from "@material-ui/core";
import Mailchimp from "./components/Mailchimp";
import Team from "./components/Team";
import Careers from "./components/Careers";
import TeamMember from "./components/team/TeamMember";
import useGaTracker from './useGaTracker'

function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {

  useGaTracker();

  return (
    <Grid>
      <ScrollToTop />
      <Navbar />
      <Box display={{ md: "block", lg: "none" }}>
        <Drawer />
      </Box>
      <Box display={{ xs: "none", sm: "block", md: "none" }}>
        <br />
        <br />
        <br />
      </Box>
      <Switch>
        <Route
          exact
          path="/contact/:name"
          render={(routeProps) => <TeamMember {...routeProps} />}
        />
        <Route path="/contactform" component={Contactform}></Route>
        <Route path="/subscribe" component={Mailchimp}></Route>
        <Route path="/privacypolicy" component={Privacy}></Route>
        <Route path="/termsofuse" component={Termsofuse}></Route>
        <Route path="/design&develop" component={DesignDevelop}></Route>
        <Route path="/manufacture" component={Manufacture}></Route>
        <Route path="/distribute" component={Distribute}></Route>
        <Route path="/ourbrands" component={Brands}></Route>
        <Route path="/careers" component={Careers}></Route>
        <Route path="/205113" component={Team}></Route>
        <Route path="/" component={Home}></Route>
      </Switch>
      <Footer />
    </Grid>
  );
} 

export default App;
