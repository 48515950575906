const tiers = [
  {
    id:'mike',
    src: require('../assets/Team/mike.jpg'),
    title: "Mike Hall",
    position:"Owner",
    description1: "Gather around and let me tell you of the man they call Mike Hall.",
    description2:"You have heard he created a company called Sea-Land Distributors, but did you know it was always meant to be?",
    description3:"His father and father before him were in the rigging business. The oil and grease of it ran like blood in his veins. But he did not start out to follow in their footsteps. At the tender age of 18, Mike went to work on offshore oil rigs before attending the University of New Orleans.",
    description4:"His expertise got him a job acquiring marine scrap metals through purchases of drilling rigs and navy ships. In the next few years, Mike took on jobs that would keep him traveling, his legend spreading to Tampa, and throughout the pacific rim.",
    description5:"By 32, Mike was president of a material-handling equipment manufacturer, overseeing 125 employees and running three shifts around the clock. Songs are still sung of this in the northeast United States.",
    description6:"Mike’s business acumen led him to becoming a fixer for under-performing companies, eventually leading to aiding rigging companies owned by a family friend.", 
    description7:"The experience gave him a vision; he saw the need for a wholesale distribution business in New Orleans and laid the groundwork for the creation of Sea-Land.", 
    description8:"But the legend is not over. Today, he oversees the creation of new products and devises ways to make our old products better.", 
    description9:"But that is a story for another day … "  
  },
  {
    id:'tyrus',
    src: require('../assets/Team/tyrus.jpg'),
    title: "Tyrus Smith",
    position:"Vice President",
    description1: "Meet Tyrus Smith. ",
    description2:"Scientific Name: Doing-Everythingius.",
    description3:"Dwelling in his natural habitat of Cut Off, Louisiana, Tyrus is always moving. Always learning. Always absorbing.",
    description4:"While hiking, he’s imagining how rock formations were formed. While fishing, he’s thinking about the mechanics of the fishing line. When racing four wheelers, he’s thinking about the physics. And when working, he’s thinking about how to do the job better.",
    description5:"We’ve tried holidays. We’ve tried a speed bump. The … man … can’t … stop.",
    description6:"Tyrus majored in Naval architecture and Marine Engineering at the University of New Orleans before entering the workforce. He drove a truck for three months before his industrious and eclectic nature got him quickly promoted to IT technician, test bed operator and finally to business development manager.  ", 
    description7:"Because of his many areas of expertise, he was invited to join Sea-Land Distributors (Manufacturis Maximus) where, as Vice President, he oversees our day to day operations whether in the machine shop, the warehouse or the drawing board.  " 
  },
  {
    id:'leonard',
    src: require('../assets/Team/leonard.jpg'),
    title: "Leonard",
    position:"Warehouse Manager",
    description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",    hobbies: "Dancing",
  },
  {
    id:'matt',
    src: require('../assets/Team/matt.jpg'),
    title: "Matt",
    position:"Manufacturing manager",
    description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",    hobbies: "Reading",
  },
  {
    id:'stephen',
    src: require('../assets/Team/stephen.jpg'),
    title: "Stephen",
    position:"Research & development Lead",
    description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",    hobbies: "Dancing",
  },
  {
    id:'joe',
    src: require('../assets/Team/joe.jpg'),
    title: "Joe",
    position:"Lead Engineer",
    description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",    hobbies: "Reading",
  },
 
  {
    id:'tim',
    src: require('../assets/Team/tim.jpg'),
    title: "Tim",
    position:"Production Manager",
    description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",    hobbies: "Reading",
  },
  {
    id:'victoria',
    src: require('../assets/Team/tori.jpg'),
    title: "Victoria Ruiz ",
    position:"Marketing Manager",
    description1: "Victoria Ruiz is the only member of the Sea-Land Distributors squad who has commanded stormtroopers.",
    description2:"Not all of us are comfortable with this.",
    description3:"Victoria graduated from LSU with a degree in Theater Design and Technology before embarking on a decade-long career in entertainment, including stage managing for Cirque De Soleil and the Orlando Shakespeare Theater. At these positions, Victoria developed a talent as a liaison, working between artists and production.",
    description4:"Eventually, Victoria became an Entertainment Guest Experience Manager at Walt Disney World, overseeing several Disney shows. She describes this time in her career as “magical.” We have decided to interpret that as, “It was a blur of Imperial parades, nagging parents and miraculous moments. Also, I’ve been forced to listen to the Frozen soundtrack so many times you really don’t want to push me.”",
    description5:"And we don’t.",
    description6:"At Sea-Land Distributors, Victoria brings her varied skillset as our social media liaison, engaging and interacting with our audience as well as managing our content to ensure we put our best foot forward online.",    
  },
  {
    id:'caleb',
    src: require('../assets/Team/caleb.jpg'),
    title: "Caleb",
    position:"Media Creator",
    description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",    hobbies: "Reading",
  },
  {
    id:'nishma',
    src: require('../assets/Team/nishma.jpg'),
    title: "Nishma Maskey",
    position:"Front End Developer",
    description1: "Nishma Maskey is the oncoming storm. ",
    description2:"The sun rises and sets because it has her ok. She put the moon in the sky and named it. And above all, she knows what pixels on our site are ones and which are zeroes. ",
    description3:"In a phrase, she is the Front-end Developer for our websites.",
    description4:"Nishma hails from Nepal, where she became interested in the sciences from a young age, often being used as a beta tester for her brothers’ robots and inventions.",
    description5:"With only two pieces of luggage, Nishma traveled to the United States, graduating from Southeastern Louisiana University with a Bachelors’ degree in Computer Science.",
    description6:"Now at Sea-Land Distributors, she paints skies, sculpts mountains as well as builds and maintains over fourteen websites for us."
  },
 
  {
    id:'adam',
    src: require('../assets/Team/adam.jpg'),
    title: "Adam",
    position:"Videographer",
    description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",    hobbies: "Reading",
  },
  {
    id:'henry',
    src: require('../assets/Team/henry.jpg'),
    title: "Henry",
    position:"Purchasing Manager",
    description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",    hobbies: "Reading",
  },
  {
    id:'tyler',
    src: require('../assets/Team/tyler.jpg'),
    title: "Tyler",
    position:"Customer Service",
    description1: "“Who’s the good-looking guy on the videos?”",
    description2:"It’s ok. We get this question a lot. It’s this guy. ",
    description3:"You can swoon.",
    description4:"We’ll wait. ",
    description5:"Ready? Tyler Hall graduated from Southeastern Louisiana University, majoring in marketing. Immediately, Tyler went to work in our warehouse before carving out a niche for himself with our videos. ",
    description6:"As the host of our instructional videos, Tyler Hall has been the face of our e-commerce division for three years. Now, he has given up the lure of the screen to become our full-time customer support consultant and Haas Stainless Technical Advisor.",
    description7:"Yes, you can actually talk to Tyler!",
    description8:"You know, about cable railing.",
    description9:"Sexy, sexy cable railing. "
  },
  {
  id:'kira',
  src: require('../assets/Team/kira.jpg'),
  title: "Kira",
  position:"System Administrator",
  description1: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown  printer took a galley of type and scrambled it to make a type  specimen book. It has survived not only five enturies, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets ontaining Lorem Ipsum  passages, and more recently with desktop publishing software  like Aldus PageMaker including versions of Lorem Ipsum.",  
},
  
 
 
];

export default tiers;