import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import ImgOpt from './ImgOpt'
import Header1 from "../assets/we-design-develop-manufacture-distribute.jpg";
import Header1b from '../assets/webp/we-design-develop-manufacture-distribute.webp';
import Header2 from "../assets/design.jpg";
import Header2b from "../assets/webp/design.webp";
import Header3 from "../assets/develop.jpg";
import Header3b from "../assets/webp/develop.webp";
import Header4 from "../assets/distribute-packages.jpg";
import Header4b from "../assets/webp/distribute-packages.webp";
import Header5 from "../assets/our-brands.jpg";
import Header5b from "../assets/webp/our-brands.webp";
import Content from "./Content";
import BrandContent from "./BrandContent";
import Drawer from "./Drawer";
import Contact from "./Contact";
import About from "./About";
import { Box, Grid } from "@material-ui/core";
import BackToTop from "./Backtotop";
import { Link } from "react-router-dom";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Helmet } from "react-helmet";


const useStyles = makeStyles((theme) => ({
  legend: {
    fontSize: "25px",
    color: "#fff",
    backgroundColor:'#091a2d',
    padding: "10px",
    position: "absolute",
    zIndex: "999",
    margin: "0 auto",
    left: 0,
    right: "-68%",
    top: "83%",
    textAlign: "center",
    width: "17%",
    borderRadius: "10px",
    border:'2px solid #00be00',
    "&:hover": {
      transform: "scale(1.1)",
      transition: "transform .5s ease-in-out",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "5px",
      padding: "5px",
      top: "85%",
      border:'1px solid #00be00',
    },
  },
}));

function Home() {

  const classes = useStyles();

  const arrowStyles = {
    position: 'absolute',
    zIndex: 2,
    // top: 'calc(50% - 15px)',
    top: '5%',
    width: '3%',
    height: '100%',
    color:'#00be00',
    cursor: 'pointer',
    '&hover':{
      top:0,
      bottom:0,
      backgroundColor:'lightgrey',
      opacity:'.4',
      
    }
   
};
  return (
    <Grid >
      <Helmet>
        <title>
          Sea-Land Distributors
        </title>
      </Helmet>
      <BackToTop />
       <Box display={{ xs: "block", sm: "none" }}>
        <Drawer />
      </Box> 
      <Carousel 
       autoPlay
        transitionTime={1500}
        interval={3500}
        infiniteLoop
        showThumbs={false}
        showStatus={true}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
                     <ArrowBackIosIcon onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15, }}/>
          )
      }
      renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (        
                  <ArrowForwardIosIcon onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}/>
          )
      }> 
        <Grid>
          <ImgOpt src={Header1b} fallbacksrc={Header1} alt="We design, develop and manufacture"/>
        </Grid>
        <Grid>
        <ImgOpt src={Header2b} fallbacksrc={Header2} alt="Design and Develop"/>
          <Link to="./design&develop">
            <p className={classes.legend}>DESIGN & DEVELOP</p>
          </Link>
        </Grid>

        <Grid>
        <ImgOpt src={Header3b} fallbacksrc={Header3} alt="Manufacturing Machines"/>
          <Link to="./manufacture">
            <p className={classes.legend}>MANUFACTURE</p>
          </Link>
        </Grid>
        <Grid>
        <ImgOpt src={Header4b} fallbacksrc={Header4} alt="Distributing Products"/>
          <Link to="./distribute">
            <p className={classes.legend}>DISTRIBUTE</p>
          </Link>
        </Grid>
        <Grid>
        <ImgOpt src={Header5b} fallbacksrc={Header5} alt="Brands We Manufacture"/>
            <Link to="./ourbrands">
            <p className={classes.legend} style={{right: "-1%",}}>OUR BRANDS</p>         
          </Link>
        </Grid>
      </Carousel>
      <Content />
      <BrandContent />
      <Contact />
      <About />
    </Grid>
  );
}

export default Home;
