import Container from '@material-ui/core/Container';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';

// import wra from '../assets/logos/wra.png';
import erig from '../assets/logos/e-riggings.png';
import erigb from '../assets/logos/webp/e-riggings.webp';
import pro from '../assets/logos/prostrand.png';
import prob from '../assets/logos/webp/prostrand.webp';
import lyn from '../assets/logos/lynx.png';
import lynb from '../assets/logos/webp/lynx.webp';
import sea from '../assets/logos/sea-strand.png';
import seab from '../assets/logos/webp/sea-strand.webp';
import fib from '../assets/logos/fiberloy.png';
import fibb from '../assets/logos/webp/fiberloy.webp';
import haa from '../assets/logos/haas.png';
import haab from '../assets/logos/webp/haas.webp';
import tyl from '../assets/logos/tyler.png';
import tylb from '../assets/logos/webp/tyler.webp';
import cre from '../assets/logos/crenshaw.png';
import creb from '../assets/logos/webp/crenshaw.webp';
import lft from '../assets/logos/lft.png';
import lftb from '../assets/logos/webp/lft.webp';
import tri from '../assets/logos/trident.png';
import trib from '../assets/logos/webp/trident.webp';
import hen from '../assets/logos/henry.png';
import henb from '../assets/logos/webp/henry.webp';
import tho from '../assets/logos/thor-tex.png';
import thob from '../assets/logos/webp/thor-tex.webp';
import rop from '../assets/logos/ropeze.png';
import ropb from '../assets/logos/webp/ropeze.webp';
import '../App.css'
import {  Link } from "react-router-dom";
import ReactWOW from 'react-wow';
import ImgOpt from './ImgOpt';

const useStyles = makeStyles({
  color:{  
    filter: 'grayscale(100%)',
    '&:hover': {
     filter:'none'
   },       
  }   ,
  bg:{
    backgroundColor: '#d6d9dd',
    padding: '50px 0px',
    marginTop: '100px',   
  },
  text:{
    color: '#231f20',
    marginBottom: '40px',
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "55px",
  },
  link:{
    textDecoration:'none',
    color: '#231f20'
  }
});

export default function Content() {
        const classes = useStyles();
      
    return (
      <Grid className={classes.bg}  id="brands"  style={{ display:'flex' }}>
        <div  style={{flexGrow:'1'}}>
       <ReactWOW animation='fadeIn'>
         <Typography component="h2" align="center" className={classes.text} >
         <Link to="/ourbrands" className={classes.link}> OUR BRANDS</Link>
        </Typography>
      <Container maxWidth="md" component="main">
      <Grid container spacing={2} justify="center" alignItems="center"> 
      <Grid item xs={12} sm={12} md={12} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link} style={{textAlign:'center'}}>
           <ImgOpt src={erigb} fallbacksrc={erig} alt="E-Rigging" style={{height:'50px'}} className={classes.color}/>
           </Link>
           </Grid>
           </Grid>
      <Grid container spacing={2} justify="center" alignItems="center">        
           <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link}>
           <ImgOpt src={haab} fallbacksrc={haa} alt="HAAS Stainles" style={{height:'100px'}} className={classes.color}/>
           </Link>
           </Grid>
           <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link}>
           <ImgOpt src={creb} fallbacksrc={cre} alt="Crenshaw" style={{ height:'100px'}} className={classes.color}/> </Link>
           </Grid>
           <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link}>
           <ImgOpt src={tylb} fallbacksrc={tyl} alt="Tyler Tools"  style={{ height:'100px'}} className={classes.color}/> </Link>
           </Grid> 
           <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
         <Link to="/ourbrands" className={classes.link}>
         <ImgOpt src={trib} fallbacksrc={tri} alt="Trident"  style={{ height:'100px'}} className={classes.color}/> </Link>
           </Grid> 
      </Grid> 

    
      <Grid container spacing={2} justify="center" alignItems="center">
      <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
      <Link to="/ourbrands" className={classes.link}>
      <ImgOpt src={prob} fallbacksrc={pro} alt="prostrand"   style={{ height:'100px'}}  className={classes.color}/> </Link>
           </Grid>
           <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link}>
           <ImgOpt src={seab} fallbacksrc={sea} alt="Sea-Land"   style={{ height:'100px'}} className={classes.color}/> </Link>
           </Grid> 
           {/* <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link}>
           <img src={wra} alt=""  style={{ height:'100px'}}/> </Link>
           </Grid> */}
           <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link}>
           <ImgOpt src={henb} fallbacksrc={hen} alt="Henry"   style={{ height:'100px'}} className={classes.color}/> </Link>
           </Grid>  
           <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link}>
           <ImgOpt src={ropb} fallbacksrc={rop} alt="Ropeze"  style={{ height:'100px'}} className={classes.color}/></Link>
           </Grid>                
      </Grid> 

         <Grid container spacing={2} justify="center" alignItems="center">
         <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
           <Link to="/ourbrands" className={classes.link}>
           <ImgOpt src={fibb} fallbacksrc={fib} alt="Fiberloy"  style={{ height:'110px'}} className={classes.color}/> </Link>
           </Grid> 
         <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
         <Link to="/ourbrands" className={classes.link}>
         <ImgOpt src={lynb} fallbacksrc={lyn} alt="Lynx"  style={{ height:'100px'}} className={classes.color}/> </Link>       
           </Grid>     
           <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
         <Link to="/ourbrands" className={classes.link}>
         <ImgOpt src={lftb} fallbacksrc={lft} alt="LFT"  style={{ height:'60px'}} className={classes.color}/></Link> 
           </Grid>     
         <Grid item xs={6} sm={6} md={3} style={{textAlign:'center'}}>
         <Link to="/ourbrands" className={classes.link}>
         <ImgOpt src={thob} fallbacksrc={tho} alt="Thor-Tex"  style={{ height:'100px'}} className={classes.color}/> </Link>
           </Grid>         
      </Grid>        
</Container>
</ReactWOW>
</div>
</Grid>
    )
}