import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Img1 from '../assets/order-packing.jpg'
import Img1b from '../assets/webp/order-packing.webp'
import Img2 from '../assets/distribute.jpg'
import Img2b from '../assets/webp/distribute.webp'
import ModalImage from "react-modal-image";
import ReactWOW from 'react-wow';
import { Helmet } from 'react-helmet';

const useStyles = makeStyles(theme => ({
  pagetitle: {
    color: "#231f20",
    paddingTop: "30px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "55px",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px", 
    },
  },
  pagesubtitle: {
    fontFamily: "Poppins, sans-serif",
    paddingBottom: "40px",
    fontSize: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "23px", 
    },
  },
  pageContent: {
    padding: "30px",
    fontSize: "18px",
    fontFamily: "Poppins, sans-serif",
  },
  card: {
    maxHeight: '340px'
  },
  pick:{
    height: '400px',
     display: 'block',     
    marginLeft: 'auto', 
    marginRight: 'auto', 
    padding:'10px',
    [theme.breakpoints.down("md")]: {
      height: '220px',
    },
  },
  tcell:{
    fontSize: "18px",
    fontFamily: "Poppins, sans-serif",
    color:'black'
  },
}));

export default function Product() {
  const classes = useStyles();

  return (
    <Grid>
      <Helmet>
        <title>
          Distribute
        </title>
      </Helmet>   
        <ReactWOW animation='fadeIn'>  
      <Container maxWidth="md" component="main">
        <Typography component="h2"   align="center" className={classes.pagetitle}>
          DISTRIBUTE
        </Typography>
        <Typography component="h2" align="center" className={classes.pagesubtitle}>
        Optimized Process for Same Day Order Processing
        </Typography>
      </Container>

      <Container maxWidth="md" component="main">
      <Grid container spacing={2} alignItems="flex-end">                    
          <Grid item  xs={12} sm={6} md={6}>
          <ModalImage
                      small={Img1b}
                      large={Img1b} hideDownload={true} hideZoom={true}
                      className={classes.card} alt="order packing"
                    />               
              </Grid>  
              <Grid item  xs={12} sm={6} md={6}>
              <ModalImage
                      small={Img2b}
                      large={Img2b} hideDownload={true} hideZoom={true}
                      className={classes.card} alt="distributing order"
                    />              
              </Grid>  
              <Typography component={'span'} className={classes.pageContent}>
              Taking care of customers by having stock and shipping quickly, accurately, and efficiently. The same energy and creativeness used to develop products was used to develop a streamlined and efficient pick, pack, and ship operation. All orders received by 2:00 pm ship out the same day! 
                 </Typography>        
        </Grid>
        {/* <Typography component="h2"  align="center"  className={classes.pagesubtitle} style={{padding: '5px'}} >Pick, Pack & Ship Capacity
                    </Typography> */}
                    {/* <img src={Pick} alt="" className={classes.pick}/> */}
       </Container>
        
       
      <Grid style={{marginBottom: '50px'}}></Grid>
     </ReactWOW>
      </Grid>
  );
}