import Container from "@material-ui/core/Container";
import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import ReactWOW from "react-wow";
import tiers from './Teaminfo'
import Box from "@material-ui/core/Box";
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    color: "#231f20",
    paddingTop: "30px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "55px",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
  },
  pagesubtitle: {
    fontFamily: "Poppins, sans-serif",
    fontSize: "37px",
    color:'#00be00',
    [theme.breakpoints.down("md")]: {
      fontSize: "23px",
    },
  },
  pageContent:{
    fontFamily: "Poppins, sans-serif",
    fontSize: "18px",
    fontWeight:'300',
    color:'grey',
    margin:'2px',
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  title:{
    borderLeft:'5px solid #00be00',
    paddingLeft:'5px',
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
  position:{
    fontSize: "24px",
    [theme.breakpoints.down("md")]: {
      fontSize: "12px",
    },
  },
}));

function handleTab(tab) {
  var i, x;
  x = document.getElementsByClassName("popup");
  for (i = 0; i < x.length; i++) {
    x[i].style.display = "none";
  }

  document.getElementById(tab).style.display = "flex";
 document.getElementById('content').scrollIntoView();
}
function handleClose(event) {
  var target = event.target;
  target.parentElement.parentElement.style.display = "none";
}
export default function Content() {
  const classes = useStyles();

  return (
    <div>
      <Helmet>
        <title>
          The Team
        </title>
      </Helmet>
      <Container maxWidth="lg" component="main">
        <Typography component="h2" align="center" className={classes.pagetitle}>
          THE TEAM
        </Typography>
        <Typography align="center" className={classes.pagesubtitle}  >
          We are the faces behind all the Design and Development.
        </Typography>
      </Container>
      {/* large view */}
      <Box display={{ xs: "none", sm: "none", md:'none', lg:'block' }}>
      <ReactWOW animation="fadeIn">
        <Container  maxWidth="xl"   id="content" style={{ paddingTop:'70px' }}> 
            {tiers.map((tier) => (
               <Grid container key={tier.title} id={tier.id} spacing={1} justify="center" style={{ 
                display:'none', 
                marginBottom:'5px', backgroundColor:'#e4e4e4'}}  className="popup" >  
              <Grid item  xs={12} md={4} sm={3} xl={3}  alignItems="center"  style={{padding:'14px', display:'flex',alignItems:'center'}}>
              <img src={tier.src} alt="" className="teamimga" style={{margin:'auto'}}/>
              </Grid>
              <Grid item xs={12} md={9} sm={9} xl={9} className="teamdesa" style={{padding:'40px'}}>
              <span onClick={handleClose} class="closebtn"> &times; </span> 
              <div>               
                <h1 className={classes.title}>{tier.title}</h1>
                <p className={classes.position}>{tier.position}</p>
                <p className={classes.pageContent}>
                {tier.description1}
                </p>
                <p className={classes.pageContent}>
                {tier.description2}
                </p>
                <p className={classes.pageContent}>
                {tier.description3}
                </p>
                <p className={classes.pageContent}>
                {tier.description4}
                </p>
                <p className={classes.pageContent}>
                {tier.description5}
                </p>
                <p className={classes.pageContent}>
                {tier.description6}
                </p>
                <p className={classes.pageContent}>
                {tier.description7}
                </p>
                <p className={classes.pageContent}>
                {tier.description8}
                </p>
                <p className={classes.pageContent}>
                {tier.description9}
                </p>
                 
                  {/* <div>
                    <a href="https://www.linkedin.com/mikehall" target="_blank" style={{ color: "black" }}  >
                      <LinkedInIcon fontSize="large" className={classes.icon} />
                    </a>
                    <a href="https://www.instagram.com/mikehall" target="_blank" style={{ color: "black" }}  >
                      <InstagramIcon fontSize="large"  className={classes.icon} />
                    </a>
                    <a href="mailto:mike@sldrigging.com" target="_blank" style={{ color: "black" }} >
                      <EmailIcon fontSize="large" className={classes.icon} />
                    </a>
                  </div>                 */}
              </div>
              </Grid>
              </Grid>
))}
      
          {/* all the people begin */}
         
          <Grid  container spacing={2} style={{ marginBottom: "20px", marginTop:"8px" }} >
            <Grid  item xs={12}md={4} sm={6} xl={3} align="center"  onClick={()=> handleTab('mike')} > 
            <div class="container">  
              <div className="mike" align="center"/> 
              <p style={{margin:'1'}}><strong>Mike Hall</strong></p>
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Mike Hall</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}>Owner</p></div>
              </div>
              </div>     
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('tyrus')}>
            <div class="container">  
              <div className="tyrus" />  
            <p style={{margin:'1'}}><strong>Tyrus Smith</strong></p>            
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Tyrus Smith</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}>Vice President</p></div>
              </div>
              </div>    
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('leonard')}>
            <div class="container">  
              <div className="leonard" />  
            <p style={{margin:'1'}}><strong>Leonard</strong></p>            
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Leonard</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}>Warehouse Manager</p></div>
              </div>
              </div>    
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('matt')}>
            <div class="container">  
              <div className="matt" />  
            <p style={{margin:'1'}}><strong>Matt</strong></p>            
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Matt</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}>Research & Development Lead</p></div>
              </div>
              </div>    
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('joe')}>
            <div class="container">  
              <div className="joe" />  
            <p style={{margin:'1'}}><strong>Joe</strong></p>            
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Joe</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}>Lead Engineer</p></div>
              </div>
              </div>    
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('tim')}>
            <div class="container">  
              <div className="tim" />  
            <p style={{margin:'1'}}><strong>Tim</strong></p>            
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Tim</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}>Production Manager</p></div>
              </div>
              </div>    
            </Grid>
            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('stephen')}>
            <div class="container">  
              <div className="stephen" />  
            <p style={{margin:'1'}}><strong>Stephen</strong></p>            
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Stephen</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}>Research & Development Lead</p></div>
              </div>
              </div>    
            </Grid>

            <Grid item xs={12} md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('victoria')}>
            <div class="container">  
              <div className="tori" /> 
              <p style={{margin:'1'}}><strong>Victoria Ruiz</strong></p>
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Victoria Ruiz</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}> Marketing Manager</p></div>
              </div>
              </div> 
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('caleb')}>
            <div class="container">  
              <div className="caleb" /> 
              <p style={{margin:'1'}}><strong>Caleb</strong></p>
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Caleb</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}> Media Creator</p></div>
              </div>
              </div> 
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('kira')}>
            <div class="container">  
              <div className="kira" /> 
            <p style={{margin:'1'}}><strong>Kira</strong></p>
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Kira</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}> System Administrator</p></div>
              </div>
              </div> 
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('nishma')}>
            <div class="container">  
              <div className="nishma" /> 
            <p style={{margin:'1'}}><strong>Nishma Maskey</strong></p>
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Nishma Maskey</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}> Front End Developer</p></div>
              </div>
              </div> 
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('adam')}>
            <div class="container">  
              <div className="adam" /> 
              <p style={{margin:'1'}}><strong>Adam</strong></p>
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Adam</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}> Videographer</p></div>
              </div>
              </div> 
            </Grid>

            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('tyler')}>
            <div class="container">  
              <div className="tyler" /> 
              <p style={{margin:'1'}}><strong>Tyler</strong></p>
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Tyler </strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}> Customer Service</p></div>
              </div>
              </div> 
            </Grid>
            
            <Grid item xs={12}md={4} sm={6} xl={3} align="center" onClick={()=> handleTab('henry')}>
            <div class="container">  
              <div className="henry" /> 
            <p style={{margin:'1'}}><strong>Henry</strong></p>
              <div class="middle">            
                <div class="text">
                  <p style={{margin:'1'}}><strong>Henry</strong></p>
                  <p style={{fontSize:'16px', margin:'1'}}>Purchasing Manager</p></div>
              </div>
              </div> 
            </Grid>

            
            
          </Grid>
        </Container>
      </ReactWOW>
      </Box>

      {/* mobile team page */}
      <Box display={{ xs: "block", sm: "block", md:'blcok', lg:'none' }}>
        <Container  maxWidth="md" style={{marginTop:'10px'}}>
          {/* End hero unit */}
          <Grid container spacing={1}>
          {tiers.map((tier) => (               
              <Grid item  xs={6}  sm={4}md={4}  align="center" alignItems="center" justify="center" >
              <img src={tier.src} alt="" className="teamimga" />             
                <p style={{fontSize:'14px'}}><strong>{tier.title}</strong></p>
                <p style={{fontSize:'12px'}}>{tier.position}</p>
              </Grid>
              
              ))}

          </Grid>
          </Container>
          </Box>
    </div>
  );
}
