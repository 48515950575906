import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { Grid, Typography, Card, Container } from "@material-ui/core";
import tiers from "../components/Brandinfo";
import Lft from "./../assets/logos/lft.png";
import Cre from "./../assets/logos/crenshaw.png";
import Uni from "./../assets/logos/fiberloy.png";
import ReactWOW from "react-wow";
import ImgOpt from './ImgOpt'
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    color: "#231f20",
    paddingTop: "31px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "55px",    
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
  },
  pagesubtitle: {
    fontFamily: "Poppins, sans-serif",
    paddingBottom: "40px",
    fontSize: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "20px",
    },
  },
  pointer: {
    cursor: "none",
  },
  media: {
    // height: "160px",
    width:"100%",
    height:"auto"
},
  link: {
    textDecoration: "none",
  },
  cardContent: {
    height: "45px",
    fontFamily: "Poppins, sans-serif",
    textAlign:'center'
  },
  button: {    
    justifyContent: "center",
    backgroundColor: "#091a2d",
    fontSize:'16px',
    border:'none',
    padding:'8px',
    cursor:'pointer',
    margin: "20px auto",
    display: "flex",
    color: "#ffffff",
    fontFamily: "Poppins, sans-serif",
    '&:hover': {
      backgroundColor: "#00be00",
      color: "#091a2d",
  },

},
}));

function Brands() {
  const classes = useStyles();
  return (
    
    <Grid  id="brands"  style={{ display:'flex' , marginBottom: "50px" }}>  
      <Helmet>
        <title>
          Our Brands
        </title>
      </Helmet>
        <div  style={{flexGrow:'1'}} >
        <ReactWOW animation="fadeIn">
          <Container maxWidth="md" component="main"  >
            <Typography
              component="h2"
              align="center"
              className={classes.pagetitle}
            >
              OUR BRANDS
            </Typography>
            <Typography
              component="h2"
              align="center"
              className={classes.pagesubtitle}
            >
              Innovative Products with a Focus on Quality
            </Typography>
          </Container>

          <Container
            maxWidth="lg"
            component="main"
            style={{ paddingTop: "30px" }}
          >
            <Grid container spacing={2} justify="center" alignItems="center">
              {tiers.map((tier) => (
                <Grid item key={tier.title} xs={12} sm={4} md={3} style={{textAlign:'center'}}>
                  <Card >
                    <a
                      href={tier.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className={classes.link}
                    >                     

                      <ImgOpt src={tier.fallbacksrc} fallbacksrc={tier.src} alt="Sea-Land" className={classes.media} />

                      <CardContent className={classes.cardContent}>
                        <Typography
                          component={"span"}
                          style={{
                            fontSize: "18px",
                            color: "black",
                            textAlign: "center",fontFamily: "Poppins, sans-serif",
                          }}
                          className={classes.size}
                        >
                          {tier.description}
                        </Typography>
                      </CardContent>
                      <button className={classes.button}>
                               Read More
                          </button>                      
                    </a>
                  </Card>
                </Grid>
              ))}

              <Grid item xs={12} sm={4} md={3}>
                <Card className={classes.card}>
                  {/* <CardMedia className={classes.media} image={Lft} /> */}
                  <ImgOpt src={Lft}  alt="LFT" className={classes.media} />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      component={"span"}
                      style={{
                        fontSize: "18px",
                        color: "black",
                        textAlign: "center",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Lifting Components: Made in USA
                    </Typography>
                  </CardContent>
                  <button className={classes.button} style={{cursor:'not-allowed'}}>
                    Coming Soon...
                  </button>
                </Card>
              </Grid>

              {/* <Grid item xs={12} sm={4} md={3}>
                <Card className={classes.card}>
                <ImgOpt src={Cre}  alt="Crenshaw" className={classes.media} />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      component={"span"}
                      style={{
                        fontSize: "18px",
                        color: "black",
                        textAlign: "center",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      Innovative Swagers & Cutters: Made in USA
                    </Typography>
                  </CardContent>
                  <button className={classes.button} style={{cursor:'not-allowed'}}>
                    Coming Soon...
                  </button>
                </Card>
              </Grid> */}

              {/* <Grid item xs={12} sm={4} md={3}>
                <Card className={classes.card}>                  
                <ImgOpt src={Uni}  alt="United Strands of America" className={classes.media} />
                  <CardContent className={classes.cardContent}>
                    <Typography
                      component={"span"}
                      style={{
                        fontSize: "18px",
                        color: "black",
                        textAlign: "center",
                        fontFamily: "Poppins, sans-serif"
                      }}
                    >
                      High Performance UHMWPE Rope - Made in USA
                    </Typography>
                  </CardContent>
                  <button className={classes.button} style={{cursor:'not-allowed'}}>
                    Coming Soon...
                  </button>
                </Card>
              </Grid> */}
            </Grid>
          </Container>
          </ReactWOW>
        </div>
       
    </Grid>
  );
}

export default Brands;
