import React from 'react';
import styles from './TeamMemberStyles'
import { Grid, Container, Paper } from '@material-ui/core'
import MailOutlineIcon from "@material-ui/icons/MailOutline";
import CallIcon from "@material-ui/icons/Call";
import MessageIcon from "@material-ui/icons/Message";
import PeopleInfo from './Peopleinfo'
import Contact from './Cardcontact'



function  TeamMember(routeProps) {


  const member = PeopleInfo.find(e => (e.path === routeProps.match.params.name))
  const classes = styles();

    return ( 
      <>
        <Container maxWidth="md" component="main" style={{ margin:'30px auto'}}>    
        {console.log('route:',routeProps.match.params.name)}
        {console.log('member is',member)}
        <Grid container spacing={2} justify="center" alignItems="center" className={classes.card}> 
        <Grid item xs={12}md={6} sm={6} xl={6} align="center" >  
        <img src={member.img} alt={member.name} className={classes.img}/>          
            </Grid>
            <Grid item xs={12}md={6} sm={6} xl={6}  >
            <p className={classes.name}>{member.name}</p> 
            <p className={classes.title}> {member.title} </p>  
            <Grid
                  container
                  spacing={2}
                  alignItems="center"
                  justify="center"
                  className={classes.pagecontent}
                  style={{ padding: "10px" }}
                >
                  <Grid item xs={12} sm={6} md={5} >
                    <a href={`tel:${member.cell}`} className={classes.link}>
                      <Paper className={classes.paper}>
                        <CallIcon style={{ verticalAlign: "middle" }} />
                        <br />
                        <span style={{ fontSize: "12px", fontFamily: "Poppins, sans-serif" }}>{member.cell}</span>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6} md={5}>
                    <a
                      href={`mailto:${member.email}`}
                      className={classes.link}
                    >
                      <Paper className={classes.paper}>
                        <MailOutlineIcon style={{ verticalAlign: "middle" }} />
                        <br />
                        <span style={{ fontSize: "12px", fontFamily: "Poppins, sans-serif" }}>
                        {member.email}
                        </span>
                      </Paper>
                    </a>
                  </Grid>
                  <Grid item xs={12} sm={6} md={10}>
                  <a
                      href={member.vcf}
                      className={classes.link}
                    >                    
                      <Paper className={classes.paper}>
                        <MessageIcon style={{ verticalAlign: "middle" }} />
                        <br />
                        <span style={{ fontSize: "15px", fontFamily: "Poppins, sans-serif" }}>
                          Add to Contacts
                        </span>
                      </Paper>
                 </a>
                  </Grid>
                </Grid>
            </Grid>
      </Grid> 
      
     </Container>
     <Contact/>
</>
    );
  }

 
export default TeamMember;