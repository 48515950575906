import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactWOW from "react-wow";
import { Container, Grid, Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
const useStyles = makeStyles((theme) => ({
  pagetitle: {
    color: "#231f20",
    paddingTop: "30px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "55px",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px",
    },
  },
  pagesubtitle: {
    fontFamily: "Poppins, sans-serif",
    paddingBottom: "40px",
    paddingTop: "40px",
    fontSize: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "23px",
    },
  },
  pageContent: {
    fontSize: "14px",
    fontFamily: "Poppins, sans-serif",
  },

  mystyle: {
    padding: "7px",
    fontSize: "15px",
    fontFamily: "Roboto",
    [theme.breakpoints.down("sm")]: {
      padding: "5px",
      border: "1px solid black",
      fontSize: "12px",
    },
  },
}));

export default function Product() {
  const classes = useStyles();

  return (
    <Grid style={{ minHeight: "50vh" }}>
      <Helmet>
        <title>
          Careers
        </title>
      </Helmet>
      <ReactWOW animation="fadeIn">
        <Container maxWidth="md" component="main">
          <Typography
            component="h2"
            align="center"
            className={classes.pagesubtitle}
          >
            {" "}
            Careers{" "}
          </Typography>
        </Container>

        <Container maxWidth="md" component="main">
          <Typography style={{ textAlign: "center" }}
>
  We do not have any positions open at the moment.
  </Typography>          {/* <Grid container spacing={2}>
            <table
              style={{
                marginBottom:'20px',
                borderCollapse: "collapse",
              }}
            >
              <tbody>
                <tr>
                  <td
                    className={classes.mystyle}
                    style={{ fontWeight: "bold" }}
                  >
                    Employer:
                  </td>
                  <td className={classes.mystyle}>                   
                      Sea-Land Distributors, LLC d/b/a E-Rigging.com
                    
                  </td>
                </tr>

                <tr>
                  <td
                    className={classes.mystyle}
                    style={{ fontWeight: "bold" }}
                  >
                    Position:
                  </td>
                  <td className={classes.mystyle}>
                    Front-End Developer (Job Code: 1001)
                  </td>
                </tr>

                <tr>
                  <td
                    className={classes.mystyle}
                    style={{ fontWeight: "bold", verticalAlign:'top'}}
                  >
                    Duties:
                  </td>
                  <td className={classes.mystyle}>
                    Responsible for building, maintaining, and deploying
                    multiple brand websites using React JS, Material UI
                    Framework with the latest web development trends,
                    responsiveness, accessibility, scalability and cross-browser
                    testing; develop searchable database to serve customers
                    certificates of authenticity; develop, code and maintain
                    e-commerce site and integrate additional functionalities as
                    needed; design and build prototypes and mockups for better
                    user experience and interface; sustain projects including
                    search engine optimization, mobile responsiveness, page
                    speed, image optimization/audit; assist with digital
                    marketing services such as ads, integrated e-mail campaigns,
                    and landing pages; create HTML marketing website content and
                    graphics; utilize HTML, CSS, JavaScript, RESTful API,
                    GitHub, ReactJS, Material UI, Bootstrap, Responsive Web
                    Design, CI/CD, UX/UI, and Adobe XD to perform duties;
                    collaborate with teams on building efficient workflow and
                    improving performance using Google Analytics; convert
                    written, graphic, audio, and video components to compatible
                    web formats.
                    <br />    <br />
                    Location: 1000 Edwards Ave., Suite C, New Orleans, LA 70123
                    <br />    <br />
                    Salary: $59,000 per year
                  </td>
                </tr>

                <tr>
                  <td
                    className={classes.mystyle}
                    style={{ fontWeight: "bold", verticalAlign:'top'}}
                  >
                    Minimum Qualifications:
                  </td>
                  <td className={classes.mystyle}>
                    Education: Bachelor’s Degree in Computer Engineering,
                    Computer Science, Electrical Engineering, Electronic
                    Engineering, or related field of study (will accept
                    equivalent foreign degree);
                    <br />    <br />
                    Training: None;
                    <br />    <br />
                    Experience: None;
                    <br />    <br />
                    Other Requirements: Must have completed one (1) academic
                    semester of coursework and/or academic research work which
                    included use of all the following: HTML, CSS, JavaScript,
                    RESTful API, GitHub, ReactJS, Material UI, Bootstrap,
                    Responsive Web Design, CI/CD, UX/UI, and Adobe XD.
                    <br />    <br />
                    Will also accept any suitable combination of education,
                    training and/or work experience.
                  </td>
                </tr>

                <tr>
                  <td
                    className={classes.mystyle}
                    style={{ fontWeight: "bold", verticalAlign:'top'}}
                  >
                    Employer Contact:
                  </td>
                  <td className={classes.mystyle}>
                    Tyrus Smith     <br />Vice President
                    <br />
                    Sea-Land Distributors, LLC d/b/a E-Rigging.com
                    <br />
                    1000 Edwards Ave., Suite C<br />
                    New Orleans, LA 70123
                    <br />
                  </td>
                </tr>
              </tbody>
            </table>
          </Grid> */}
        </Container>
      </ReactWOW>
    </Grid>
  );
}
