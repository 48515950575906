const tiers = [
  {
    src: require('../assets/logos/e-rigging.png'),
    fallbacksrc: require('../assets/logos/webp/e-rigging.webp'),
    description: 'Online store for Cable Railing, Rigging, Marine, & Stainless Steel Hardware ',
    link: 'https://www.e-rigging.com/'
},
  {
    src: require('../assets/logos/haas.png'),
    fallbacksrc: require('../assets/logos/webp/haas.webp'),
    description: 'Architectural, Rigging, & Marine Stainless Products ',
    link: 'https://www.e-rigging.com/Cable-Railing_c_3634.html'
},
  {
    src: require('../assets/logos/thor-tex.png'),
    fallbacksrc: require('../assets/logos/webp/thor-tex.webp'),
    description: 'Polyester Web Slings and Round Slings ',
    link: 'https://www.thor-tex.com/'
}, 
{
  src: require('../assets/logos/henry.png'), 
    fallbacksrc: require('../assets/logos/webp/henry.webp'),
  description: 'Superior Block Products ',
  link: 'https://www.e-rigging.com/Henry-Block-and-Pulley-Products'
},
    //  {
    //   src: require('../assets/logos/lft.png'),
    //      description: 'Swivel Hoist Rings: Made in USA',
    //      link: 'https://www.e-rigging.com/',
    //    },
      {
    src: require('../assets/logos/sea-strand.png'),
    fallbacksrc: require('../assets/logos/webp/crenshaw.webp'),
    description: 'Innovative Swagers & Cutters: Made in USA   ',
    link: 'https://www.e-rigging.com/crenshaw-products'
},
  // {
  //   src: require('../assets/logos/fiberloy.png'),
  //   description: 'UHMWPE Rope Stronger Than Steel',
  //   link: 'https://www.e-rigging.com/'
  // },
  {
    src: require('../assets/logos/sea-strand.png'),
    fallbacksrc: require('../assets/logos/webp/sea-strand.webp'),
    description: 'Consistent Quality Rope Products ',
    link: 'https://www.e-rigging.com/Soft-Rope-Fittings_c_3882.html'
},

    {
        src: require('../assets/logos/ropeze.png'),
        fallbacksrc: require('../assets/logos/webp/ropeze.webp'),
        description: 'Innovative Rope Accessories  ',
        link: 'https://www.ropeze.com/'
    },
    {
      src: require('../assets/logos/prostrand.png'),
      fallbacksrc: require('../assets/logos/webp/prostrand.webp'),
      description: 'High Quality Galvanized Cable',
      link: 'https://www.e-rigging.com/Galvanized-Steel-Cable_c_3689.html'
    },    
    {
        src: require('../assets/logos/trident.png'),
        fallbacksrc: require('../assets/logos/webp/trident.webp'),
        description: 'High Quality Windlass & Mooring Chains',
        link: 'https://www.tridentmarinechain.com/'
      },
      {
        src: require('../assets/logos/lynx.png'),
        fallbacksrc: require('../assets/logos/webp/lynx.webp'),
        description: 'High Quality Industrial Chain Products  ',
        link: 'https://www.e-rigging.com/Lynx-Chain-Products'
    },
    {
      src: require('../assets/logos/tyler.png'),
      fallbacksrc: require('../assets/logos/webp/tyler.webp'),
      description: 'Rigging Hoists and Tools               ',
      link: 'https://www.e-rigging.com/Tyler-Tool-Products'
  },
  {
    src: require('../assets/logos/fiberloy.png'),
    fallbacksrc: require('../assets/logos/webp/united.webp'),
    description: 'High Performance UHMWPE Rope - Made in USA               ',
    link: 'https://www.usa-rope.com'
},

      // {
      //     src: require('../assets/logos/wraptor.png'),
      //     description: 'Innovative Swagers & Cutters: Made in USA   ',
      //     link: 'https://www.e-rigging.com/'
      // },
    
  
];

  export default tiers;