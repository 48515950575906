import React from "react";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Img1b from "../assets/webp/manufacture.webp";
import Img2b from "../assets/webp/secondary-equipment.webp";
// import Lft from '../assets/logos/lft.png'
import Tho from "../assets/logos/thor-tex.png";
import Thob from "../assets/logos/webp/thor-tex.webp";
import Haa from "../assets/logos/haas.png";
import Haab from "../assets/logos/webp/haas.webp";
import Hen from "../assets/logos/henry.png";
import Henb from "../assets/logos/webp/henry.webp";
import Tyl from "../assets/logos/tyler.png";
import Tylb from "../assets/logos/webp/tyler.webp";
import Cre from "../assets/logos/crenshaw.png";
import Creb from "../assets/logos/webp/crenshaw.webp";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import ReactWOW from "react-wow";
import ImgOpt from './ImgOpt'
import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  pagetitle: {
    color: "#231f20",
    paddingTop: "30px",
    fontFamily: "Poppins, sans-serif",
    fontWeight: 600,
    fontSize: "55px",
    [theme.breakpoints.down("md")]: {
      fontSize: "35px", 
    },
    
  },
  pagesubtitle: {
    fontFamily: "Poppins, sans-serif",
    paddingBottom: "40px",
    fontSize: "25px",
    [theme.breakpoints.down("md")]: {
      fontSize: "23px", 
    },
  },
  pageContent: {
    padding: "30px",
    fontSize: "18px",
    fontFamily: "Poppins, sans-serif",
  },
  img: {
    maxHeight: "65px",
  },
}));

export default function Product() {
  const classes = useStyles();

  return (
    <Grid>
      <Helmet>
        <title>
          Manufacture
        </title>
      </Helmet>
        <ReactWOW animation='fadeIn'>
      <Container maxWidth="md" component="main" >
        <Typography component="h2" align="center" className={classes.pagetitle} >
          MANUFACTURE
        </Typography>
        <Typography component="h2" align="center" className={classes.pagesubtitle}>
          High Production CNC Machines and Secondary Equipment
        </Typography>
      </Container>

      <Container maxWidth="md" component="main">
        <Grid container spacing={2} alignItems="flex-end">
          <Grid item xs={12} sm={6} md={6}>
            <ModalImage
              small={Img1b}
              large={Img1b}
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="manufacture CNC machines"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <ModalImage
              small={Img2b}
              large={Img2b}
              hideDownload={true}
              hideZoom={true}
              className={classes.card}
              alt="secondary equipment"
            />
          </Grid>
          <Typography component={'span'}  className={classes.pageContent}>
            There are not many things more satisfying than making your own
            products. Challenges and hurdles must be overcome, but when those
            are conquered and production rolls, it gets in your blood. Made in
            USA with pride!
          </Typography>
        </Grid>
        <Typography component="h2"
          align="center"
          className={classes.pagesubtitle}
          style={{ padding: "5px"}}
        >
          {" "}
          Brands We Manufacture
        </Typography>
        <Grid container spacing={2} justify="center" alignItems="center">
          <Grid item md={2} sm={3} xs={6} style={{ textAlign: "center" }}>
            <Link to="/ourbrands">
            <ImgOpt src={Thob} fallbacksrc={Tho} alt="Ropeze" className={classes.img}/>
            </Link>
          </Grid>
          <Grid item md={2} sm={3} xs={6} style={{ textAlign: "center" }}>
            <Link to="/ourbrands">
            <ImgOpt src={Creb} fallbacksrc={Cre} alt="Crenshaw" className={classes.img}/>
            </Link>
          </Grid>
          <Grid item md={2} sm={3} xs={6} style={{ textAlign: "center" }}>
            <Link to="/ourbrands">
            <ImgOpt src={Haab} fallbacksrc={Haa} alt="Haas Stainless" className={classes.img}/>
            </Link>
          </Grid>
          <Grid item md={2} sm={3} xs={6} style={{ textAlign: "center" }}>
            <Link to="/ourbrands">
            <ImgOpt src={Henb} fallbacksrc={Hen} alt="Henry" className={classes.img}/>
            </Link>
          </Grid>
          <Grid item md={2} sm={3} xs={6} style={{ textAlign: "center" }}>
            <Link to="/ourbrands">
            <ImgOpt src={Tylb} fallbacksrc={Tyl} alt="Tyler" className={classes.img}/>
            </Link>
          </Grid>
        </Grid>
      </Container>
      <Grid style={{ marginBottom: "50px" }}></Grid>
      </ReactWOW>
    </Grid>
  );
}
