import Mikeimg from './../../assets/Team/mike.jpg'
import Mikevcf from '../../assets/Business Cards/mike.vcf';

import Tyrusimg from './../../assets/Team/tyrus.jpg'
import Tyrusvcf from '../../assets/Business Cards/tyrus.vcf';

import Leonardimg from './../../assets/Team/leonard.jpg'
import Leonardvcf from '../../assets/Business Cards/leonard.vcf'

import DavidJrimg from './../../assets/Team/tylerh.jpg'
import DavidJrvcf from '../../assets/Business Cards/davidjr.vcf'

import Davidimg from './../../assets/Team/tylerh.jpg'
import Davidvcf from '../../assets/Business Cards/david.vcf'

import Josephimg from './../../assets/Team/joe.jpg'
import Josephvcf from '../../assets/Business Cards/joseph.vcf'

import Mattimg from './../../assets/Team/matt.jpg'
import Mattvcf from '../../assets/Business Cards/matt.vcf'

import Stephenimg from './../../assets/Team/stephen.jpg'
import Stephenvcf from '../../assets/Business Cards/stephen.vcf'

import Timimg from './../../assets/Team/tim.jpg'
import Timvcf from '../../assets/Business Cards/tim.vcf'

import Toriimg from './../../assets/Team/tori.jpg'
import Torivcf from '../../assets/Business Cards/tori.vcf'

import Adamimg from './../../assets/Team/adam.jpg'
import Adamvcf from '../../assets/Business Cards/adam.vcf'

import Henryimg from './../../assets/Team/henry.jpg'
import Henryvcf from '../../assets/Business Cards/henry.vcf'

import Markimg from './../../assets/Team/Mark.jpg'
import Markvcf from '../../assets/Business Cards/mark.vcf'

import Tylerimg from './../../assets/Team/tyler.jpg'
import Tylervcf from '../../assets/Business Cards/tyler.vcf'

import Barbaraimg from './../../assets/Team/tylerh.jpg'
import Barbaravcf from '../../assets/Business Cards/barbara.vcf'

import Melissaimg from './../../assets/Team/tylerh.jpg'
import Melissavcf from '../../assets/Business Cards/melissa.vcf'

import Nishmaimg from './../../assets/Team/nishma.jpg'
import Nishmavcf from '../../assets/Business Cards/nishma.vcf'

import Carolynimg from './../../assets/Team/tylerh.jpg'
import Carolynvcf from '../../assets/Business Cards/carolyn.vcf'

const info = [
    {
      path: 'mike',
      name:'Mike Hall',
      title: 'President | Owner',
      cell:'985-640-4369',
      email:'mike@sldrigging.com',
      img: Mikeimg,
      vcf: Mikevcf
  },
  {
    path: 'tyrus',
    name:'Tyrus Smith',
    title: 'Vice - President',
    cell:'985-696-5169',
    email:'smith@sldrigging.com',
    img: Tyrusimg,
    vcf: Tyrusvcf
},
  {
    path: 'leonard',
    name: 'Leonard Williams, Sr.',
    title: 'Warehouse Manager',
    cell: '504-628-7067',
    email:'leonard@sldrigging.com',
    img: Leonardimg,
    vcf: Leonardvcf
  },
  {
    path: 'matt',
    name: 'Matt Champagne',
    title: 'Manufacturing Manager',
    email: 'Matt@SLDRigging.com',
    cell: '985-677-2589',
    img: Mattimg,
    vcf: Mattvcf
  },
  {
    path: 'davidjr',
    name: 'David Warren Jr',
    title: 'Facilities Maintenance',
    email: 'davidjr@SLDRigging.com',
    cell: '504-952-9438' ,
    img: DavidJrimg,
    vcf: DavidJrvcf
  },
  {
    path: 'david',
    name: 'David Warren',
    title: 'National Sales Manager',
    email: 'david@HaasStainless.com',
    cell: '504-952-8351',
    img: Davidimg,
    vcf: Davidvcf
  },
  {
    path: 'joseph',
    name: 'Joseph Hagensee',
    title: 'Lead Engineer | CNC Programmer',
    email: 'Joseph@SLDRigging.com',
    cell: '504-515-6064',
    img: Josephimg,
    vcf: Josephvcf
  },
  {
    path: 'stephen',
    name: 'Stephen Baay',
    title: 'Lead Research & Development',
    email: 'Steve@SLDRigging.com',
    cell: '504-400-8343',
    img: Stephenimg,
    vcf: Stephenvcf
  },
  {
    path: 'tim',
    name: 'Tim Pierson',
    title: 'Production Manager',
    email: 'Tim@SLDRigging.com',
    cell: '504-322-9522',
    img: Timimg,
    vcf: Timvcf
  },
  {
    path: 'tori',
    name: 'Tori "Bird" Ruiz',
    title: 'Marketing Manager',
    email: 'tori@SLDrigging.com',
    cell: '504-239-9992',
    img: Toriimg,
    vcf: Torivcf
  },
  {
    path: 'adam',
    name: 'Adam Tomblin',
    title: 'Video Content Creator',
    email: 'Adam@E-Rigging.com',
    cell: '828-289-0339',
    img: Adamimg,
    vcf: Adamvcf
  },
  {
    path: 'mark',
    name: 'Mark Simoneaux',
    title: 'Purchasing Manager',
    email: 'mark@SLDRigging.com',
    cell: '504-324-2532',
    img: Markimg,
    vcf: Markvcf
  },
  {
    path: 'henry',
    name: 'Henry Patron',
    title: 'Purchasing Manager',
    email: 'Henry@SLDRigging.com',
    cell: '504-343-3798',
    img: Henryimg,
    vcf: Henryvcf
  },
  {
    path: 'tyler',
    name: 'Tyler Hall',
    title: 'HAAS Pro Installer Manager',
    email: 'Tyler@E-Rigging.com',
    cell: '985-290-9983',
    img: Tylerimg,
    vcf: Tylervcf
  },
  {
    path: 'barbara',
    name: 'Barbara Calcagno',
    title: 'Office Manager',
    email: 'Barbara@SLDRigging.com',
    cell: '504-352-9400',
    img: Barbaraimg,
    vcf: Barbaravcf
  },
  {
    path: 'melissa',
    name: 'Melissa Burchardt',
    title: 'Controller',
    email: 'Melissa@SLDRigging.com',
    cell: '504-736-9477  ',
    img: Melissaimg,
    vcf: Melissavcf
  },
  // {
  //   path: 'bethany',
  //   name: 'Bethany Barnoski',
  //   title: 'Merchandising Analyst',
  //   email: 'Bethany@E-Rigging.com',
  //   cell: '570-956-9632',
  //   img: Bethanyimg,
  //   vcf: Bethanyvcf
  // },
   {
    path: 'nishma',
    name: 'Nishma Maskey',
    title: 'Lead Front End Developer',
    email: 'Nishma@SLDRigging.com',
    cell: '320-266-4261',
    img: Nishmaimg,
    vcf: Nishmavcf
  },
  // {
  //   path: 'logan',
  //   name: 'Logan Klein',
  //   title: 'Web Developer',
  //   email: 'Logan@E-Rigging.com',
  //   cell: '504-251-2168',
  //   img: Loganimg,
  //   vcf: Loganvcf
  // },
  // {
  //   path: 'kira',
  //   name: 'Kira Champagne',
  //   title: 'Data Manager',
  //   email: 'Kira@SLDRigging.com',
  //   cell: '985-278-8316',
  //   img: Kiraimg,
  //   vcf: Kiravcf
  // },
  // {
  //   path: 'caleb',
  //   name: 'Caleb Brabham',
  //   title: 'Product Photographer',
  //   email: 'Caleb@E-Rigging.com',
  //   cell: '903-926-9044',
  //   img: Calebimg,
  //   vcf: Calebvcf
  // },
  // {
  //   path: 'ed',
  //   name: 'Ed Toscano',
  //   title: 'Sales Manager',
  //   email: 'Ed@E-Rigging.com',
  //   cell: 'n/a',
  //   img: Edimg,
  //   vcf: Edvcf
  // },
   {
    path: 'carolyn',
    name: 'Carolyn Hemb',
    title: 'Purchase Manager',
    email: 'Carolyn@SLDrigging.com',
    cell: '504-427-1792',
    img: Carolynimg,
    vcf: Carolynvcf
  },
  // {
  //   path: 'leigh',
  //   name: 'Leigh Smith',
  //   title: 'Invoicing/Credits',
  //   email: 'Leigh@E-Rigging.com',
  //   cell: 'n/a',
  //   img: Leighimg,
  //   vcf: Leighvcf
  // },
  // {
  //   path: 'accountant',
  //   name: 'Accountant',
  //   title: 'Staff Accountant',
  //   email: 'accountant@E-Rigging.com',
  //   cell: 'n/a',
  //   img: Accountantimg,
  //   vcf: Accountantvcf
  // },
  // {
  //   path: 'brandt',
  //   name: 'Brandt Arceneaux',
  //   title: 'Marketing Analyst',
  //   email: 'n/a',
  //   cell: 'n/a',
  //   img: Brantimg,
  //   vcf: Brantvcf
  // },
    
  ];
  
    export default info;
